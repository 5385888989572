<template>
  <div class="about">
    <p>
		<span style="background-color:#ffffff; font-size:10pt; font-weight:bold; letter-spacing:1pt">关于我们</span>
	</p>
	<p>
		<span>&#xa0;</span>
	</p>
	<p>
		<span style="background-color:#ffffff; font-size:10pt; letter-spacing:0.3pt">但丁科技有限公司成立于2018年，是一家由AI行业资深从业者组成的创业公司，致力于将人工智能和计算机视觉技术应用于图像和视频处理领域。</span>
	</p>
	<p>
		<span style="background-color:#ffffff; font-size:10pt; letter-spacing:0.3pt">我们同时也向商业客户提供各种可定制的AI图像视频处理方案，欢迎与我们联系合作。</span>
		<br />
		<span style="background-color:#ffffff; font-size:10pt; letter-spacing:0.3pt">邮箱：wearebug@gmail.com</span>
		<br />
		<span style="background-color:#ffffff; font-size:10pt; letter-spacing:0.3pt">微信：12145508</span>
	</p>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo: {
    title: 'about', // set a title
    meta: [
      {
        name: 'ABOUT',
        content: 'ABOUT CONTENT',
      },
    ],
    link: [
      {
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/',
      },
    ],
  },
}
</script>
